'use client';
import { ArrowNarrowLeft, Button, Logo } from '@v2/ui';
import { useRouter } from 'next/navigation';
import { PropsWithChildren } from 'react';

export function PartnerAuthContainer(props: PropsWithChildren) {
  const { children } = props;
  const router = useRouter();
  return (
    <div className="min-h-screen bg-gray-900">
      <div className="mb-12 flex h-20 items-center justify-between px-4 md:px-6">
        <Button
          variant="ghost"
          onClick={() => {
            router.back();
          }}
          className="p-0"
        >
          <ArrowNarrowLeft pathProps={{ stroke: '#667085' }} />
        </Button>
        <Logo isClickable variant="white" size={51} />

        <div></div>
      </div>

      <div className="mx-auto flex w-full flex-col gap-y-10 px-4 sm:w-[560px] md:px-0">
        {children}
      </div>
    </div>
  );
}
