'use client';

import { v2Links } from '@utils/navigation/links';
import { Button, ErrorText, Input, Label, Typography } from '@v2/ui';
import { useManualLogin } from '@v2/views/auth/use-auth';
import { useSession } from 'next-auth/react';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { PartnerAuthContainer } from '../components';

export function PartnerLogin() {
  const router = useRouter();
  const { update } = useSession();

  const [{ formik, loading }] = useManualLogin({
    email: '',
    onSuccessCallback: () => {
      router.push(v2Links.home);
      setTimeout(() => {
        update();
        router.refresh();
      }, 500);
    },
  });

  return (
    <PartnerAuthContainer>
      {' '}
      <Typography variant="h1" size={'dsm'} className="text-center text-white">
        Partner login
      </Typography>
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-y-10">
        <div className="flex flex-col gap-y-4">
          <div className="grid w-full items-center gap-1.5">
            <Label htmlFor="email">Email address </Label>
            <Input
              extent="md"
              type="email"
              name="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              errorText={formik.errors.email}
            />
            {formik.errors.email && (
              <ErrorText>{formik.errors.email}</ErrorText>
            )}
          </div>
          <div className="grid w-full items-center gap-1.5">
            <Label htmlFor="email">Password </Label>
            <Input
              extent="md"
              type="password"
              name="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              errorText={formik.errors.password}
            />
            {formik.errors.password && (
              <ErrorText>{formik.errors.password}</ErrorText>
            )}
          </div>

          <Link
            className="text-sm font-semibold text-primary-500"
            href={v2Links.partnerForgotPassword}
          >
            Forgot your password?
          </Link>
        </div>
        <Button
          name="signinButton"
          type="submit"
          variant="primary"
          size="xl"
          className="w-full"
          isLoading={loading}
        >
          Sign in
        </Button>
      </form>
    </PartnerAuthContainer>
  );
}
