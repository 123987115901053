'use client';
import { Skeleton } from '@v2/ui';
import dynamic from 'next/dynamic';

const PartnersHero = dynamic(
  () => import('./components').then((mod) => mod.PartnersHero),
  {
    ssr: false,
    loading: () => <Skeleton className="h-[440px] w-full md:h-[736px]" />,
  }
);
const PartnersSection = dynamic(
  () => import('./components').then((mod) => mod.PartnersSection),
  {
    ssr: false,
    loading: () => <Skeleton className="h-[778px] w-full md:h-[736px]" />,
  }
);
const SectionHero = dynamic(
  () => import('./components').then((mod) => mod.SectionHero),
  {
    ssr: false,
    loading: () => <Skeleton className="h-[375px] w-full md:h-[560px]" />,
  }
);
export async function PartnersLandingPage({}) {
  return (
    <main>
      <PartnersHero />
      <PartnersSection />
      <SectionHero />
    </main>
  );
}
