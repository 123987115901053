'use client';

import { AlertToast, AlertTriangle, Button, Input, Typography } from '@v2/ui';
import { loginSchema } from '@v2/views/auth/use-auth';
import { useFormik } from 'formik';
import { useRouter } from 'next/navigation';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { PartnerAuthContainer } from '../components';
import { checkUserExisting } from '@v2/action/test';

export function PartnerResetPassword() {
  const router = useRouter();

  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: loginSchema,
    onSubmit: async (values) => {
      setLoading(true);

      const existingRes = await checkUserExisting(values);

      if (data == 'User not found.') {
        setLoading(false);
        return toast.custom(
          (t) => (
            <AlertToast
              {...t}
              title="Oops, something went wrong"
              content={existingRes.data}
              icon={<AlertTriangle />}
            />
          ),
          { position: 'top-right' }
        );
      }

      setLoading(false);
    },
  });
  return (
    <PartnerAuthContainer>
      <div>
        <Typography
          variant="h1"
          size={'dsm'}
          className="mb-10 text-center text-white"
        >
          Reset your password
        </Typography>
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-y-3">
          <Input
            extent="md"
            required
            type="email"
            placeholder="Enter your email"
            name="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            isError={formik.touched.email && Boolean(formik.errors.email)}
            errorText={formik.errors.email}
          />

          <Button
            type="submit"
            variant="primary"
            size="xl"
            className="w-full"
            isLoading={loading}
          >
            Continue
          </Button>
        </form>
      </div>
    </PartnerAuthContainer>
  );
}
