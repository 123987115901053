'use client';
import { v2Links } from '@utils/navigation/links';
import {
  Button,
  ToggleGroup,
  ToggleGroupItem,
  TravelAgency,
  TravelAgent,
  Typography,
} from '@v2/ui';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { useState } from 'react';
import { AffiliateUserType } from './types';

export function PartnersSignupView() {
  const [selected, setSelected] = useState<AffiliateUserType | null>(null);
  const router = useRouter();
  const handleContinue = () => {
    router.push(v2Links.partnerSignupSlug(selected!));
  };

  return (
    <div className="flex w-full flex-col gap-y-12">
      <Typography size="dmd" className="text-center font-bold text-gray-800">
        Sign up as{' '}
      </Typography>

      <ToggleGroup
        type="single"
        variant="outline"
        className="gap-3"
        value={selected || ''}
        onValueChange={(val: AffiliateUserType) => setSelected(val)}
      >
        <ToggleGroupItem
          value="Agency"
          className="md:[176px] flex w-full flex-col items-center gap-y-5 px-0 md:h-[210px] md:w-[274px]"
        >
          <TravelAgency />
          <Typography size="tlg" className="font-bold">
            Agency
          </Typography>
        </ToggleGroupItem>
        <ToggleGroupItem
          // disabled={true}
          value="Agent"
          className="md:[176px] md: flex h-[210px] w-full flex-col items-center gap-y-5 px-0 md:w-[274px]"
        >
          <TravelAgent />
          <Typography size="tlg" className="font-bold">
            Agent
          </Typography>
        </ToggleGroupItem>
      </ToggleGroup>

      <div>
        <Button
          className="w-full"
          disabled={!selected?.length}
          onClick={handleContinue}
        >
          Continue
        </Button>
      </div>
      {/* <Typography
          size="tmd"
          className="text-gray-500 font-medium  text-center mt-6"
        >
          Already have an account?&nbsp;
          <Link href={v2Links.login} className="text-primary-500">
            Log in
          </Link>
        </Typography>
      </div> */}
    </div>
  );
}
